import { BodyText, Heading } from 'components/atoms/typography';

import { AppPageProps } from 'gatsby/types';
import ContactForm from 'components/organisms/forms/Contact';
import FullWidthImage from '@svelte/reactify/FullWidthImage';
import PageContentLayout from 'components/layouts/PageContentLayout';
import { PressContactPageQuery } from 'gatsby/graphqlTypes';
import React from 'react';
import ResponsiveImage from '@svelte/reactify/ResponsiveImage';
import { graphql } from 'gatsby';

export { Head } from 'components/atoms/Head';
type Props = AppPageProps<PressContactPageQuery>;

export const query = graphql`
  query PressContactPage {
    c: strapiPressContactPage {
      pageHeading
      mainText
      formTitle
      formSuccessTitle
      formSuccessText
      image {
        ...CdnImageData
      }
    }
  }
`;

const PressContact: React.FC<Props> = props => {
  const data = props.data.c;
  const image = data?.image;
  const pageContext = props.pageContext;
  if (!data || !image) {
    throw Error('no data');
  }

  return (
    <PageContentLayout footerNavigation={pageContext.footerNavigation}>
      <FullWidthImage
        display="block lg:hidden"
        data={image}
        height="h-rhythm10 md:h-rhythm11"
        eager
      />
      <div className="min-h-screen lg:flex">
        <div className="px-4 py-rhythm4 sm:px-6 lg:px-12 lg:pt-rhythm6 xl:px-20 2xl:px-32">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <Heading level={1}>{data.pageHeading}</Heading>
              <BodyText>{data.mainText}</BodyText>
            </div>

            <div className="mt-rhythm4">
              <ContactForm
                successTitle={data.formSuccessTitle}
                successBody={data.formSuccessText}
                title={''}
                reason="PRESS"
              />
            </div>
          </div>
        </div>

        <ResponsiveImage
          width="flex-1"
          height="h-auto"
          display="hidden lg:block"
          data={image}
          eager
        />
      </div>
    </PageContentLayout>
  );
};

export default PressContact;
